.Container {
    width: 100%;
    height: 400px;
    border: 1px solid #73261B;
    position: relative;
    overflow: hidden; 

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(117.65% 169.13% at 2.27% -17.65%, rgba(255, 61, 142, 0.6) 0%, rgba(255, 84, 61, 0.6) 100%);
        opacity: 0;
        transition: opacity 1s ease;
        z-index: 0;
    }

    img {
        padding: 77px 0 24px 60px;
        z-index: 1000;
    }
    
    .Text {
        width: 90%;
        height: 149px;
        padding: 0 5%;
        z-index: 1000;
    
        h3 {
            padding-bottom: 24px;
        }
    
        p {
            color: #FF543D;
        }
    }

    &:hover::before {
        opacity: 1;
    }

    &:hover {
        p {
            color: #FFFFFF;
            z-index: 10;
        }
    }
}

@media (max-width: 768px) {
    .Container {
        height: auto;

        img {
            padding-top: 24px;
            padding-left: 24px;
        }
        

        .Text {
            height: auto;
            width: calc(100% - 48px);
            padding: 0 24px 24px 24px;

            h3 {
                height: 81 px;
            }
        }
    }
}

@media (max-width: 480px) {
    .Container {
        img {
            padding-top: 40px;
            padding-left: 20px;
        }

        .Text {
            padding-bottom: 40px;

            h3 {
                font-weight: 700;
                font-size: 24px;
            }

            p {
                font-size: 18px;
            }
        }
    }
}