.Container {
    width: 100%;
    height: 432px;

    img {
        width: 282px;
        height: 345px;
    }

    .Text {
        padding-top: 14px;

        h3 {
            font-weight: 700;
            font-size: 24px;
            color: #BFBFBF;
            margin-bottom: 4px;
        }
    
        p {
            font-size: 18px;
            color: #BFBFBF;
        }
    }
}

@media (max-width: 768px) {
    .Container {
        padding: 0 50%;
    }
}