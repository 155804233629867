.container {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #00000052;
    padding: 1rem 2.5rem;

    .menuIcon {
        display: none;
    }

    .logo {
        img {
            width: 11.75rem;
            height: 2.75rem;
        }
    }

    .pageNavigate {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        gap: 2.5rem;
        flex: 1;
        width: 100%;
        padding-right: 12.5rem;

        .link {
            width: auto;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 600;

            &:hover {
                color: #FF543D;
            }
        }

        .active {
            color: #FF543D;

            &:hover {
                opacity: 1;
                text-decoration: none;
            }
        }
    }

    .closeIcon {
        display: none;
    }

    @media (max-width: 768px) {
        .pageNavigate {
            padding-right: 2.5rem;
            justify-content: flex-end;
        }

        .menuIcon {
            display: none;
        }
    }

    @media (max-width: 480px) {
        .menuIcon {
            display: block;
            font-size: 1.5rem;
            cursor: pointer;
            color: #FFFFFF;
        }

        .pageNavigate {
            display: none;
            flex-direction: column;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 90%;
            height: 95%;
            background-color: rgba(0, 0, 0, 0.952);
            backdrop-filter: blur(5px);
            padding: 5%;
            gap: 1rem;
            align-items: flex-start;
            justify-content: flex-end;
            z-index: 1000;
        
            &.show {
                display: flex;

                .closeIcon {
                    display: block;
                }
            }
        
            .link {
                width: 100%;
                text-align: left;
                font-size: 48px;
                font-weight: 200;
            }

            .active {
                color: #FF543D;
                font-weight: 500;
            }
        
            .closeIcon {
                position: absolute;
                top: 30px;
                right: 30px;
                font-size: 1.5rem;
                color: #FFF;
                cursor: pointer;
                z-index: 1010;
            }
        }
    }
}