.Container {
    display: none;
    position: relative;
    overflow: hidden;
    width: 100%;

    .Members {
        display: flex;
        transition: transform 0.5s ease;
    }

    .Slider {
        min-width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;

        span {
            height: 10px;
            width: 10px;
            margin: 0 5px;
            border-radius: 50%;
            display: inline-block;
            transition: background-color 0.3s;
        }
    }
}

@media (max-width: 768px) {
    .Container {
        display: block;
    }
}