@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.body {
  font-family: "Manrope", sans-serif;
  background: #000000;
}

.container {
  display: flex;
  width: 100%;
  height: 542px;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/ImgService/Frame\ 79.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  gap: 126px;

  .header {
    align-items: center;
    justify-content: center;
    width: 877px;
    height: 77px;
    text-align: center;

    h1 {
      font-size: 5vw;
      font-weight: 600;
      line-height: 76.8px;
      color: #FFFFFF;
    }
  }
}

.website {
  width: 100%;
  
  .title {
    box-sizing: border-box;
    width: 100%;
    height: 174px;
    border-top: 1px solid #73261B;
    border-bottom: 1px solid #73261B;
    justify-content: space-between;
    padding: 32px 40px;
    background-image: linear-gradient(to left, #FF543D00, #FF543D33);
    display: flex;
    flex-direction: row;
    gap: 126px;

    .head {
      width: 486px;
      height: 110px;
      color: #FFFFFF;

      h2 {
        width: 100%;
        font-size: 3.125vw;
        font-weight: 400;
        line-height: 54.64px;

        br {
          display: block;
        }
      }
    }

    .dn {
      box-sizing: border-box;
      width: 588px;
      height: 110px;
      color: #BFBFBF;

      p {
        box-sizing: border-box;
        padding: 26px 0;
        width: 100%;
        height: 58px;
        font-size: 1.875vw;
      }

      .WebsiteTablet {
        display: none;
      }
    }
  }

  .description {
    width: 100%;
    height: 748px;
    display: flex;
    flex-direction: row;

    .col_1 {
      display: flex;
      box-sizing: border-box;
      width: 50%;
      height: 748px;
      border-right: 1px solid #73261B;
      border-bottom: 1px solid #73261B;
      padding: 80px 0 0 100px;

      .desktop {
        width: 34.297vw;
        height: 21.328vw;
      }
    }

    .col_2 {
      box-sizing: border-box;
      width: 50%;
      height: 748px;
      border-left: 1px solid #73261B;
      border-bottom: 1px solid #73261B;
      padding: 80px 40px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      color: #BFBFBF;

      .oop {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        gap: 8px;
        padding: 16px 0;

        .xicon {
          width: 48px;
          height: 48px;
        }
        
        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .design {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        gap: 8px;
        padding: 16px 0;

        .speedy {
          width: 48px;
          height: 48px;
        }

        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .authorize {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        gap: 8px;
        padding: 16px 0;

        .key {
          width: 48px;
          height: 48px;
        }

        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .update {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        gap: 8px;
        padding: 16px 0;

        .srch {
          width: 48px;
          height: 48px;
        }

        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }
    }
  }
}

.mobileDesign {

  .heading {
    box-sizing: border-box;
    width: 100%;
    height: 174px;
    border-top: 1px solid #73261B;
    border-bottom: 1px solid #73261B;
    background-image: linear-gradient(to left, #FF543D00, #FF543D33);
    justify-content: space-between;
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    gap: 126px;

    .head {
      width: 486px;
      height: 110px;
      font-size: 3.125vw;
      font-weight: 400;
      line-height: 54.64px;
      color: #FFFFFF;

      h2 {
        width: 100%;
      }

      .line {
        display: block;
      }
    }

    .subtext {
      width: 588px;
      height: 110px;

      .cap {
        padding: 11.5px 0;
        height: 87px;
        font-size: 1.875vw;
        font-weight: 400;
        line-height: 28.8px;
        color: #BFBFBF;

        p {
          width: 100%;
        }
      }

      .MobileDesginTablet {
        display: none;
      }
    }
  }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 748px;

    .col1 {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 748px;
      border-bottom: 1px solid #73261B;
      border-right: 1px solid #73261B;
      padding: 80px 40px;
      color: #BFBFBF;
      gap: 40px;

      .xdesign {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .designX {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .Webhook {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .WebHook {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .token2 {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .Token2 {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .ReloadFrame {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .FrameReload {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }
    }

    .col2 {
      box-sizing: border-box;
      width: 50%;
      height: 748px;
      border-bottom: 1px solid #73261B;
      padding: 80px 0 0 150px;

      .mobileIcon {
        width: 18.594vw;
        height: 22.5vw;
      }
    }
  }
}

.iotInjection {

  .caption {
    box-sizing: border-box;
    width: 100%;
    height: 174px;
    border-top: 1px solid #73261B;
    border-bottom: 1px solid #73261B;
    background-image: linear-gradient(to left, #FF543D00, #FF543D33);
    justify-content: space-between;
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    gap: 126px;

    .header {
      color: #FFFFFF;
      width: 486px;
      height: 110px;
      font-size: 3.125vw;
      font-weight: 400;
      line-height: 54.64px;

      h2 {
        width: 100%;
      }
    }

    .textsub {
      box-sizing: border-box;
      width: 588px;
      height: 110px;
      justify-content: space-between;
      color: #BFBFBF;

      .textDescription {
        width: 100%;
        height: 87px;
        font-size: 1.875vw;
        font-weight: 400;
        line-height: 28.8px;
        padding: 11.5px 0;

        p {
          width: 100%;
        }
      }

      .iotInjectionTablet {
        display: none;
      }
    }
  }

  .Content {
    box-sizing: border-box;
    width: 100%;
    height: 748px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Col1 {
      box-sizing: border-box;
      width: 50%;
      height: 748px;
      border-right: 1px solid #73261B;
      border-bottom: 1px solid #73261B;
      padding: 80px 0 0 134px;

      .iot {
        width: 29.063vw;
        height: 26.012vw;
      }
    }

    .Col2 {
      box-sizing: border-box;
      width: 50%;
      height: 748px;
      display: flex;
      flex-direction: column;
      padding: 80px 40px;
      border-left: 1px solid #73261B;
      border-bottom: 1px solid #73261B;
      color: #BFBFBF;
      gap: 40px;

      .security {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .safety {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .performance {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .quality {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .key2 {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .RSAkey {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }

      .Support {
        box-sizing: border-box;
        width: 100%;
        height: 117px;
        padding: 16px 0;
        gap: 8px;

        .supportTeam {
          width: 48px;
          height: 48px;
        }

        p {
          height: 29px;
          font-size: 24px;
          font-weight: 400;
          line-height: 28.8px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    height: 328px;

    .header {

      h1 {
        font-size: 7.292vw;
        font-weight: 600;
        line-height: 67.2px;
      }
    }
  }

  .website {
    
    .title {
      box-sizing: border-box;
      width: 100%;
      height: 203px;
      flex-direction: column;
      gap: 25px;

      .head {
        box-sizing: border-box;
        width: 324px;

        h2 {
          width: 100%;
          height: 55px;
          font-size: 5.208vw;

          br {
            display: none;
          }
        }
      }

      .dn {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 60px;
        gap: 5.469vw;

        p {
          box-sizing: border-box;
          width: 588px;
          height: 58px;
          font-size: 3.125vw;
          padding: 0;
        }

        .WebsiteTablet {
          display: block;
          width: 7.813vw;
          height: 7.813vw;
          border-radius: 6.51vw;
          background-color: #FF543D;
        }

        .WebsiteTablet:hover {
          background-color: #FFFFFF;
          color: #FF543D;
        }
      }
    }

    .description {
      height: 864px;

      .col_1 {
        padding: 80px 0 0 40px;
        height: 864px;
        
        .desktop {
          width: 39.583vw;
          height: 24.615vw;
        }
      }

      .col_2 {
        height: 864px;

        .oop {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .design {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .authorize {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .update {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }
      }
    }
  }

  .mobileDesign {


    .heading {
      box-sizing: border-box;
      width: 100%;
      height: 230px;
      flex-direction: column;
      gap: 25px;

      .head {
        box-sizing: border-box;
        width: 600px;
        height: 55px;
        font-size: 5.208vw;
        font-weight: 600;
        line-height: 54.64px;

        h2 {
          width: 100%;
        }

        .line {
          display: none;
        }
      }

      .subtext {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 5.469vw;

        .cap {
          padding: 0;
          width: 586px;
          height: 87px;
          font-size: 3.125vw;

          p {
            width: 100%;
          }
        }

        .MobileDesginTablet {
          display: block;
          width: 7.813vw;
          height: 7.813vw;
          border-radius: 50px;
          background-color: #FF543D;
        }

        .MobileDesginTablet:hover {
          background-color: #FFFFFF;
          color: #FF543D;
        }
      }
    }

    .content {
      height: 864px;

      .col1 {
        height: 864px;

        .xdesign {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .Webhook {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .token2 {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .ReloadFrame {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }
      }

      .col2 {
        padding: 80px 0 0 40px;
        height: 864px;

        .mobileIcon {
          width: 39.583vw;
          height: 39.583vw;
        }
      }
    }
  }

  .iotInjection {
    
    .caption {
      box-sizing: border-box;
      width: 100%;
      height: 203px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .header {
        width: 600px;
        height: 55px;
        font-size: 5.208vw;
        font-weight: 400;
        line-height: 54.64px;

        h2 {
          width: 100%;
        }
      }

      .textsub {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        gap: 42px;
        width: 100%;

        .textDescription {
          padding: 0;
          width: 586px;
          font-size: 3.125vw;

          p {
            width: 100%;
          }
        }

        .iotInjectionTablet {
          display: block;
          width: 7.813vw;
          height: 7.813vw;
          border-radius: 50px;
          background-color: #FF543D;
        }

        .iotInjectionTablet:hover {
          color: #FF543D;
          background-color: #FFFFFF;
        }
      }
    }

    .Content {
      height: 864px;

      .Col1 {
        padding: 80px 0 0 40px;
        height: 864px;
  
        .iot {
          width: 39.583vw;
          height: 35.43vw;
        }
      }

      .Col2 {
        height: 864px;

        .security {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .performance {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .key2 {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }

        .Support {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 3.125vw;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    display: none;
  }

  .website {

    .title {
      width: 100%;
      height: 230px;

      .head {
        box-sizing: border-box;
        width: 324px;
        height: 55px;
        gap: 24px;

        h2 {
          font-size: 8.333vw;
        }

        br {
          display: none;
        }
      }

      .dn {
        box-sizing: border-box;
        width: 100%;
        height: 87px;

        p {
          height: 87px;
          font-size: 5vw;
        }

        .WebsiteTablet {
          display: none;
        }
      }
    }

    .description {
      width: 100%;
      height: 1056px;
      display: flex;
      flex-direction: column;

      .col_1 {
        width: 100%;
        height: 349px;
        border: none;
        border-bottom: 1px solid #73261B;

        .desktop {
          width: 63.333vw;
          height: 39.383vw;
        }
      }

      .col_2 {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        height: 707px;
        padding: 0 24px 80px;
        gap: 24px;
        border: none;
        border-bottom: 1px solid #73261B;

        .oop {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .design {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .authorize {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .update {
          width: 100%;
          height: 146px;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }
      }
    }
  }

  .mobileDesign {

    .heading {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 285px;
      padding: 32px 24px;
      gap: 24px;

      .head {
        width: 100%;
        height: 110px;
        font-size: 8.333vw;
      }

      .subtext {
        width: 100%;
        height: 87px;

        .cap {
          width: 100%;
          height: 87px;
          font-size: 5vw;
        }
  
        .MobileDesginTablet {
          display: none;
        }
      }
    }

    .content {
      width: 100%;
      height: 1113px;
      display: flex;
      flex-direction: column;

      .col1 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        order: 2;
        height: 649px;
        padding: 0 24px 80px;
        width: 100%;
        gap: 24px;

        .xdesign {
          width: 100%;
          height: 146px;
          padding: 16px 0;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .Webhook {
          width: 100%;
          height: 146px;
          padding: 16px 0;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .token2 {
          width: 100%;
          height: 146px;
          padding: 16px 0;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }

        .ReloadFrame {
          width: 100%;
          height: 146px;
          padding: 16px 0;

          p {
            height: 58px;
            font-size: 5vw;
          }
        }
      }

      .col2 {
        order: 1;
        width: 100%;
        height: 464px;
        border: none;
        border-bottom: 1px solid #73261B;
        padding: 80px;

        .mobileIcon {
          width: 63.333vw;
          height: 63.333vw;
        }
      }
    }
  }

  .iotInjection {

    .caption {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 285px;
      border: none;
      border-bottom: 1px solid #73261B;
      border-top: 1px solid #73261B;
      gap: 25px;

      .header {
        width: 100%;
        height: 110px;
        font-size: 8.333vw;
      }

      .textsub {
        width: 100%;
        height: 87px;

        .textDescription {
          width: 100%;
          height: 87px;
          font-size: 5vw;
          font-weight: 400;
        }

        .iotInjectionTablet {
          display: none;
        }
      }
    }

    .Content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 1052px;

      .Col1 {
        box-sizing: border-box;
        width: 100%;
        height: 432px;
        border: none;
        border-bottom: 1px solid #73261B;

        .iot {
          width: 63.333vw;
          height: 56.688vw;
        }
      }

      .Col2 {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border: none;
        border-bottom: 1px solid #73261B;
        width: 100%;
        height: 620px;
        padding: 0 24px 80px;
        gap: 25px;

        .security {
          padding: 16px 0;
          width: 100%;
          height: 117px;
          gap: 8px;

          p {
            height: 29px;
            font-size: 5vw;
            font-weight: 400;
          }
        }

        .performance {
          padding: 16px 0;
          width: 100%;
          height: 117px;
          gap: 8px;

          p {
            height: 29px;
            font-size: 5vw;
            font-weight: 400;
          }
        }

        .key2 {
          padding: 16px 0;
          width: 100%;
          height: 117px;
          gap: 8px;

          p {
            height: 29px;
            font-size: 5vw;
            font-weight: 400;
          }
        }

        .Support {
          padding: 16px 0;
          width: 100%;
          height: 117px;
          gap: 8px;

          p {
            height: 29px;
            font-size: 5vw;
            font-weight: 400;
          }
        }
      }
    }
  }
}