@font-face {
    font-family: Manrope;
    src: url('../constants/Manrope-VariableFont_wght.ttf');
    font-weight: normal;
    font-style: normal;
}

.Container {
    font-family: 'Manrope', sans-serif;

    h1 {
        font-weight: 600;
        font-size: 64px;
        margin: 0;
    }

    h2 {
        font-weight: 600;
        font-size: 48px;
        margin: 0;
    }

    h3 {
        font-weight: 600;
        font-size: 32px;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: 24px;
        margin: 0;
    }
}

.Title {
    width: 100%;
    height: 542px;
    background-image: url('../assets/images/AboutPage/TtitleBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h1 {
        width: 90%;
        height: 154px;
        text-align: center;

        padding: 189px 5% 199px 5%;
    }
}

.Hello {
    width: 100%;
    height: 385px;

    p {
        width: 90%;
        height: 145px;

        padding: 80px 5% 160px 5%;
    }
}

.GroupPic {
    width: 100%;
    height: 715px;
}

.Value {
    width: 100%;
    height: 1138px;

    h2 {
        width: 90%;
        height: 58px;
        padding: 160px 5% 40px 5%;
    }

    .Values {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        height: 800px;
        padding: 0 5%;
    }
}

.Team {
    .Text {
        width: 50%;
        height: 169px;
        padding: 80px 0 40px 5%;

        h2 {
            margin-bottom: 24px;
        }
    }

    .Members {
        display: flex;
        flex-direction: row;
        width: 90%;
        height: 432px;
        padding: 200px 5% 0 5%;
        gap: 24px;
    }
}

.Goals {
    .Container {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .Title {
        display: none;
    }

    .Hello {
        height: auto;

        h2 {
            font-weight: 400;
            font-size: 24px;
        }
    }

    .GroupPic {
        height: 428px;
    }

    .Value {
        height: auto;

        h2 {
            height: auto;
        }

        .Values {
            height: auto;
            padding-top: 80px;
        }
    }

    .Team {
        padding-top: 80px;

        .Text {
            width: 80%;
            height: auto;
        }

        .Members {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    .Hello {
        p {
            height: auto;
            padding-top: 152px;
            padding-bottom: 40px;
        }
    }

    .GroupPic {
        height: 266px;
    }

    .Value {
        h2 {
            padding-top: 80px;
            font-size: 32px;
            font-weight: 600;
        }

        .Values {
            display: flex;
            flex-direction: column;
            height: auto;
            padding-top: 0;
            
        }
    }

    .Team {
        .Text {
            width: 90%;
            padding: 0 5% 47px 5%;

            h2 {
                font-size: 32px;
            }

            p {
                font-size: 18px;
            }
        }

        .Members {
            gap: 32px;
        }
    }

    .Goals {
        padding-top: 80px;
        padding-bottom: 201px;

        .Container {
            gap: 80px;
        }
    }
}