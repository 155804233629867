:root {
  --foundationColor: #000000;
  --mainColor: #FF543D;
  --textColor1: #BFBFBF;
  --titleColor: #FFFFFF;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.button {
  width: 238px;
  height: 48px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 56px;
  color: var(--titleColor);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  

  &:hover {
    background-color: #FF543D80;
    border: 0;
  }

  .iconArrow {
    width: 24px;
    height: 24px;
    padding-left: 10px;
  }

  .iconPhone {
    width: 24px;
    height: 24px;
    padding-left: 10px;
  }

  &.orange {
    background-color: var(--mainColor);
    
  }

  &.black {
    background-color: var(--foundationColor);
    border: 1px solid var(--mainColor);
  }
}



@media (max-width: 480px) {
  .button {
    height: auto;
    width: 100%;
    max-width: 212px;

    .iconArrow {
      width: 5vw;  // 24px
      height: 5vh;     // 24px
      padding-left: 1.302vw;  // 10px    
    }

    .iconPhone {
      display: none;
    }

    &.orange {
      font-size: 0;
      width: 40vw; 
      height: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: 'Liên hệ tư vấn'; 
        color: white; 
        font-size: 4vw; 
        
      }
    }

    &.black {
      font-size: 4vw; 
      width: 40vw; 
      height: 10vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
