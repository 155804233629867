* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.servicesSection {
  padding: 60px 0;
  text-align: center;
  background-color: var(--foundationColor);
}

.sectionTitle {
  font-size: 32px;
  color: var(--titleColor);
  margin-bottom: 40px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  justify-content: center;
  width: 100%;
  
  @media (max-width: 768px) {
    
  }
}

.card {
  background-color: rgba(8, 8, 8, 0.8);
  text-align: left;
  width: 100%;
  border: 2px solid #1F1F1F;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background: linear-gradient(to bottom, transparent, #FF543D80);
  }

  .iconImage {
    width: 100%;
    height: auto;
  }

  .title {
    font-size: 40px;
    height: 180px;
    color: #FFFFFF;
    font-weight: 400;
    padding-top: 60px;
  }

  .description {
    font-size: 24px;
    font-weight: 400;
    color: #FF9385;
    height: 108px;
  }

  .features-list {
    list-style: none;
    padding-left: 0;
    padding-top: 44px;
    min-height: 120px;
    height: 205px;

    li {
      display: flex;
      align-items: flex-start;
      font-size: 18px;
      font-weight: 400;
      color: #F1ECFF;
      padding-bottom: 12px;

      &::before {
        content: url('../../assets/images/homePageImages/homePageIconShape1.svg');
        position: relative;
        padding-right: 7px;
      }
    }
  }

  .button {
    background-color: var(--mainColor);
    border: none;
    color: white;
    padding: 8px 24px;
    border-radius: 56px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    width: 196px;
    height: 48px;
    margin-top: 60px;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: white;
      color: black; 
    }

    .iconImg {
      padding-left: 8px;
      width: 24px;
      height: 24px;
    }
  }
}

.indicators {
  display: none;
  justify-content: center;
  margin-top: 16px;

  span {
    height: 16px;
    width: 48px;
    background-color: #FF543D1A;
    margin: 4px;
    border: 1px solid #FF543D;
    border-radius: 56px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;

    &.activeDot {
      background-color: #FF543D;
    }
  }
}

.linkButton {
  display: block;
  width: 180px;
}

@media (max-width: 768px) {
  .cardContainer {
    justify-content: start;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding-left: 24px;
    padding-right: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      padding: 60px 42px;
      flex: 1 0 343px;

      .title {
        font-size: 40px;
        padding-top: 32px;
        height: 120px;
      }

      .description {
        font-size: 20px;
        padding-top: 24px;
        height: 66px;
      }

      .features-list {
        padding-top: 44px;
        height: 96px;

        li {
          font-size: 16px;
        }
      }

      .button {
        font-size: 16px;
        width: 170px;
        height: 40px;
        margin-top: 40px;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;
        background-color: white;
        color: black; 
        border: 1px solid #FF543D;

        &:hover {
          background-color: #FF543D;
        }

        .iconImg {
          padding-left: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .sectionTitle{
    font-size: 28px;
  }
  
  .cardContainer {
    overflow-x: scroll; 
    justify-content: flex-start; 
    padding: 0 !important;
    margin: 0 !important;

  }

  .card {
    scroll-snap-align: center;
    display: flex !important;
    margin-left: 23px;
    margin-right: 80px;
    flex-direction: column;
    width: 100%; 
    max-width: calc(100% - 60px);
    max-height: 1010px;
    

    .title {
      font-size: 8.33vw;
      padding-top: 6.25vw;
      height: auto !important;
    }

    .description {
      font-size: 6.67vw;
      height: auto !important;

    }

    .features-list {
      padding-top: 4.17vw;
      height: auto !important;


      li {
        font-size: 3.33vw;
      }
    }

    .button {
      width: 46vw !important;
      height: 7.5vw;
      margin-top: 6.25vw;
      font-size: 4vw !important;

      .iconImg {
        padding-left: 1px !important;
        width: 5vw !important;
        height: 5vw;
      }
    }
  }

  .indicators {
    display: flex;

    span {
      width: 2.5vw;
      height: 2.5vw;
    }
  }
}
